.about-page {
  display: flex;

  border-radius: 0px;
}
.about-jumbotron {
  background: var(--custom-orange-color);
  background: linear-gradient(
    90deg,
    var(--custom-orange-color) 61%,
    var(--custom-primary-color) 100%
  );
  padding-left: 8vw;
  padding-right: 8vw;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-bottom: 2rem;
  padding-top: 8rem;
  color: white;
}
.about-jumbotron h5 {
  width: 70%;
}
.about-jumbotron h1 {
  color: var(--custom-primary-color);
  font-weight: bolder;
}

.about-us section {
  margin: 3rem auto;
}
.about-us h2 {
  color: #5e656a;
  font-weight: bold;
}
.about-us h3 {
  color: #5e656a;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .about-jumbotron {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .about-jumbotron h5 {
    width: 100%;
  }
  .about-us section {
    margin: 1rem auto;
    text-align: justify;
  }
  .about-us h2 {
    font-size: 1.5rem;
  }
  .about-us h3 {
    font-size: 1.2rem;
  }
  .about-img {
    display: none;
  }
}
