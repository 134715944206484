:root {
  --custom-primary-color: #2c5a9e;
  --custom-secondary-color: #1e3b6f;
  --custom-tertiary-color: #539ad3;
  --custom-orange-color: rgb(251, 172, 46);
}
@media screen and (max-width: 640px) {
  body,
  html {
    width: 100vw;
    overflow-x: hidden;
  }
}
.fade-in {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.125);
  opacity: 0;
  transform: translate(0, 50%);
  visibility: hidden;
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
  will-change: opacity, visibility;
}

.navbar-collapse {
  padding: 10px !important;
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.zoom-in {
  opacity: 0;
  transform: scale(1.5);
  transition: opacity 1000ms ease-in-out, transform 1000ms ease-in-out;
  will-change: opacity, transform;
}

.zoom-is-visible {
  opacity: 1;
  transform: none;
  margin-top: 0;
}

.footer {
  background-color: #282c34;
  color: #7ea2b9;
  padding: 12vh 8vw 4vh 8vw;
  margin: 0 0;
}

.footer h4 {
  color: white;
}

.footer a {
  color: #7ea2b9;
  text-decoration: none;
}

.footer a:hover {
  color: #539ad3;
}
.social-icons-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons-container a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 10px 10px;
  color: #7ea2b9;
  border-style: solid;
  border-color: #7ea2b9;
  border-width: 1px;
  background-color: transparent;
  border-radius: 10%;
  transition: all 0.3s ease-in-out;
}

.social-icons-container a:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.social-icons-container i {
  font-size: 2.5rem;
}
