.landing-page {
  height: 100vh;
  margin-top: 4rem;
  border-radius: 0px;
}

.jumbotron {
  background: white;

  color: var(--custom-primary-color);
}
.jumbotron h1 {
  font-size: 4.5rem;
  font-weight: 1000;
  line-height: 1.2;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  color: var(--custom-primary-color);
}

.jumbotron h2 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.custom-btn {
  background: var(--custom-secondary-color) !important;
  border: none !important;
  color: white;
  border: 0px;
  border-radius: 0px;
  font-size: 1.5em;
  font-weight: 700;
  padding: 0.5em 1em;
  margin-top: 1em;
}

.custom-btn:hover {
  background: var(--custom-primary-color) !important;
}

.main-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.social-icons-container-landing {
  display: flex;
  align-items: center;
}

.social-icons-container-landing a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 10px 10px;
  color: var(--custom-primary-color);
  border-style: solid;
  border-color: var(--custom-secondary-color);
  border-width: 1px;
  background-color: transparent;
  border-radius: 10%;
  transition: all 0.3s ease-in-out;
}

.social-icons-container-landing a:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.social-icons-container-landing i {
  font-size: 2.5rem;
}

@media screen and (max-width: 768px) {
  .landing-page {
    margin-top: 1rem;
    height: 100vh;
  }
  .jumbotron {
    display: flex;
    flex-direction: column;

    margin-top: 4.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .jumbotron h1 {
    font-size: 2.5em;
    align-self: center;
    margin-top: 0.2rem;
  }
  .jumbotron h2 {
    font-size: 1.3em;
    align-self: center;
  }
  .jumbotron p {
    font-size: 0.8em;
    text-align: justify;
    margin: 0;
  }
  .custom-btn {
    align-self: center;
    width: fit-content;
    /* margin-top: 0; */
  }
  .social-icons-container-landing {
    justify-content: center;
    margin-top: 10px !important;
  }
  .main-img {
    width: 70%;
    height: 70%;
    align-self: center;
    object-fit: contain;
  }
}

.infographic {
  background-color: #e9f3f9;

  padding-top: 3rem;
  padding-bottom: 5rem;
}
.info-main-text {
  margin-bottom: 3rem;
}
.info-main-text h1 {
  font-size: 3.5rem;
  font-weight: 500;
  color: #000;
}
.info-main-text strong {
  color: var(--custom-primary-color);
}
.infographic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ebebeb;
}
.infographic-item p {
  text-align: center;
}
.number {
  font-size: 2rem;
  font-weight: 700;
  color: #000;
}
@media screen and (max-width: 768px) {
  .infographic {
    padding: 0 !important;
    height: 80vh;
  }
  .info-main-text {
    margin-top: 3rem !important;
  }
  .infographic-item {
    border-right: none;
    border-bottom: 1px solid #ebebeb;
    font-size: 0.8rem;
  }
  .number {
    font-size: 1.5rem;
  }
}

.clients {
  margin-top: 3rem;
}
.client-main-text {
  margin-bottom: 3rem;
}
.client-main-text h1 {
  font-size: 3.5rem;
  font-weight: 500;
  color: #000;
}
.client-main-text strong {
  color: #fbac2e;
}

.client-img {
  width: 70%;
  z-index: 10;
  height: 70%;
  object-fit: contain;
}
@media screen and (max-width: 768px) {
  .clients {
    margin-top: 0;
    padding: 0 !important;
  }
}

.testimonials {
  text-align: justify;
}

.profile-div {
  display: flex;
  align-items: center;
  justify-content: end;
}
.profile-card {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #c9c9c9;
}

.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--custom-primary-color);
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-info {
  margin-left: 20px;
  margin-top: 10px;
}

.profile-info h2 {
  font-size: 18px;
  margin: 0;
}

.profile-info p {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .testimonials {
    font-size: 0.9rem;
  }
  .profile-picture {
    width: 60px;
    height: 60px;
  }
  .profile-info h2 {
    font-size: 14px;
    margin: 0;
  }

  .profile-info p {
    font-size: 10px;
  }
}

.hire-developer {
  color: var(--custom-tertiary-color);
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding: 3rem 0;
  width: 50vw;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .hire-developer p {
    text-align: justify;
    margin: 0 1rem;
    font-size: 0.8rem;
  }
}
