.hire-a-pro {
  margin: 10% auto !important;
  padding: 40px !important;
  width: 80vw !important;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 768px) {
  .hire-a-pro {
    margin: 10% auto !important;
    margin-top: 30% !important;
    padding: 40px !important;
    width: 90vw !important;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  }
}
.my-button-theme {
  background-color: white !important; /* Red background color */
  color: black !important; /* White text color */
  border-color: var(--custom-orange-color) !important; /* Red border color */
  /* Add more custom CSS properties as needed */
  font-weight: 500 !important;
  width: 250px !important;
  box-shadow: none !important;
  margin: 0.5rem !important;
}

.my-button-theme:hover {
  background-color: var(--custom-orange-color) !important;
  color: white !important;
  border-color: white !important;
}

.my-button-selected {
  background-color: var(--custom-orange-color) !important;
  color: white !important;
  border-color: white !important;
  width: 250px !important;
  box-shadow: none !important;
  margin: 0.5rem !important;
}

@media screen and (max-width: 768px) {
  .my-button-theme {
    width: 100% !important;
    font-size: 1rem !important;
  }
  .my-button-selected {
    width: 100% !important;
    font-size: 1rem !important;
  }
}
.role-icon {
  margin-right: 1rem;
}

.PhoneInput input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
