html,
body {
  height: 100%;
}
.navbar {
  transition: all 500ms ease-in-out;
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
@media screen and (max-width: 768px) {
  .navbar {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.not-scrolled {
  background: white;
  height: 100px;
  color: black;
}
.scrolled {
  background: white;
  height: 90px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.navbar-brand {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.nav-links {
  margin-left: 1rem;
}
.nav-links a {
  color: black !important;
  font-size: 1.1rem;
  font-weight: 500;
}

.navbar-collapse {
  background-color: white;
  margin-left: 2rem;
}
@media screen and (max-width: 768px) {
  .navbar-collapse {
    margin-left: 0rem;
    transform: translateY(-3%);
  }
}
.dropdown-toggle::after {
  display: none !important;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: var(--custom-primary-color);
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--custom-primary-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
