.hire-page {
  display: flex;

  border-radius: 0px;
}
.hire-jumbotron {
  background: rgb(251, 172, 46);
  background: linear-gradient(
    90deg,
    rgba(251, 172, 46, 1) 61%,
    rgba(83, 154, 211, 1) 100%
  );
  padding-left: 8vw;
  padding-right: 8vw;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-bottom: 2rem;
  padding-top: 8rem;
  color: white;
}
.hire-jumbotron h5 {
  width: 70%;
}
.hire-jumbotron h1 {
  color: #539ad3;
  font-weight: bolder;
}

.hire h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: black;
  font-weight: bolder;
}
.hire span {
  color: #539ad3;
}
.hire-item {
  margin: 30px 0px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  border: 1px solid #e5e5e5;
  box-shadow: 1px 1px 49px -11px rgba(0, 0, 0, 0.67);
  border-radius: 10px;
}

.hire-btn {
  justify-content: flex-end;
  align-self: flex-end;
  display: flex;
  width: fit-content;
}

.resource-content {
  font-size: large;
}
