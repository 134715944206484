.contact-us {
  margin: 10% auto !important;
  padding: 40px !important;
  width: 80vw !important;
  border-radius: 10px;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {
  .contact-us {
    margin: 10% auto !important;
    margin-top: 30% !important;
    padding: 40px !important;
    width: 90vw !important;
    border-radius: 10px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  }
}
.form-item {
  margin: 30px 0px;
}

.form-field {
  width: 100% !important;
  padding: 10px 15px !important;
  border: none !important;
  border-bottom: 2px solid #d0d0d0 !important;
  outline: none !important;
  font-family: "Lucida Sans", "Lucida Sans Regular";
  font-size: 18px !important;
  line-height: 25px !important;
  color: #6a6a6a !important;
}

.contact-info-item {
  display: flex;
  margin: 2rem 0;

  width: 100%;
  height: 80px;
  background-color: #ffdda7;
  color: black;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  align-items: center;
}

@media (max-width: 1024px) {
  .contact-info-item {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .contact-info-item {
    font-size: 16px;
    height: 60px;
  }
}

.contact-info-item:hover {
  background-color: #fbae2e;
  color: white;
  .contact-icon {
    color: white;
  }
}
.contact-info-item p {
  margin: 0;
}

.contact-icon {
  color: #fbae2e;
  margin: 0 1rem;
}

.contact-icon:hover {
  color: white;
}

.contact-form-grp {
  margin: 2rem 0;
}
.contact-form-grp input {
  background-color: #f2f2f2 !important;
  height: 50px !important;
}
.contact-form-grp-input {
  background-color: #f2f2f2 !important;
}

.contact-btn {
  margin-top: 10px;
  width: 100%;
  background-color: var(--custom-secondary-color) !important;
}

.contact-btn:hover {
  background-color: var(--custom-primary-color) !important;
}
